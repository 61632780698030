import { FC } from 'react'
import cn from 'classnames'

import ArrowSvg from '../../public/icons/arrow-down.svg'
import styles from './SliderTop.module.scss'
import Link from 'next/link'

interface ISliderTop {
  title: string | JSX.Element
  href?: string
  total?: number
  onPrev?: () => void
  onNext?: () => void
  disableHref?: boolean
  hideButtons?: boolean
}

const SliderTop: FC<ISliderTop> = ({
  title,
  href,
  total,
  onPrev,
  onNext,
  disableHref,
  hideButtons,
}) => {
  const titleTag = (
    <div className={cn('h3', styles.title)}>
      {title}
      {total !== undefined && (
        <sup className={styles.total} aria-disabled="true">
          {' '}
          {total}
        </sup>
      )}

      <ArrowSvg />
    </div>
  )

  return (
    <div className={styles.row}>
      {href && !disableHref ? (
        <Link href={href} passHref legacyBehavior>
          <a
            className={styles.titleLink}
            aria-label={typeof title === 'string' ? title : 'Перейти в раздел'}
          >
            {titleTag}
          </a>
        </Link>
      ) : (
        titleTag
      )}
      {!hideButtons && (
        <div className={styles.buttons}>
          <button
            className={styles.button}
            type="button"
            onClick={onPrev}
            title="Предыдущий слайд"
          >
            <ArrowSvg />
          </button>
          <div className={styles.line} />
          <button
            className={cn(styles.button, styles.next)}
            type="button"
            onClick={onNext}
            title="Следующий слайд"
          >
            <ArrowSvg />
          </button>
        </div>
      )}
    </div>
  )
}

export default SliderTop
