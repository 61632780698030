import React, { FC } from 'react'
import styles from './ErrorPage.module.scss'
import MaxWidth from '../layout/MaxWidth'
import Post from '../news/Post'
import CommonSlider from '../main/CommonSlider'
import { NowReadingQuery } from '../../queries/generated/blog/schema'
import Link from 'next/link'
import InternalServerSvg from '../../public/icons/500-svg.svg'
import ErrorDynamic from './ErrorDynamic'

interface IErrorPage {
  code: number
  nowReading: NowReadingQuery
}

export const ErrorPage: FC<IErrorPage> = ({ code, nowReading }) => {
  const renderErrorContent = (code: number) => {
    const errorDetails = {
      404: {
        title: `Страница не\u00A0найдена`,
        component: <ErrorDynamic />,
      },
      500: {
        title: 'Internal Server Error',
        component: <InternalServerSvg />,
      },
    }

    const { title, component } = errorDetails[code] || errorDetails[404]

    return (
      <>
        <div className={styles.error}>
          <h1>{title}</h1>
          <p className={'big-heading'}>{code}</p>
        </div>
        <div className={styles.errorImage}>{component}</div>
        <Link href="/" className={styles.button}>
          Перейти на главную
        </Link>
      </>
    )
  }

  return (
    <>
      <MaxWidth className={styles.wrapper}>{renderErrorContent(code)}</MaxWidth>
      {nowReading?.nowReadingPosts?.length && (
        <CommonSlider
          title="Популярное"
          className={styles.slider}
          items={nowReading.nowReadingPosts.map((post) => (
            <Post
              className={styles.item}
              data={post}
              imageMaxWidth={400}
              showTags
              bigTitle
              sliceTags
            />
          ))}
          showTags
          bigTitle
          sliceTags
          slidesPerView={3}
        />
      )}
    </>
  )
}

export default ErrorPage
